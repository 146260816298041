var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h1", { staticClass: "title" }, [
        _vm._v(
          "\n    Check " +
            _vm._s(_vm.$route.params.analyzer) +
            " / " +
            _vm._s(_vm.$route.params.check) +
            "\n  "
        ),
      ]),
      _vm._v(" "),
      _c("h2", { staticClass: "subtitle" }, [
        _vm._v("On repository " + _vm._s(_vm.$route.params.repository)),
      ]),
      _vm._v(" "),
      _c("Pagination", {
        attrs: {
          api_data: _vm.$store.state.check_issues,
          name: "issues",
          store_method: "load_check_issues",
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "field" }, [
        _c("label", { staticClass: "label" }, [_vm._v("Issues since:")]),
        _vm._v(" "),
        _c("div", { staticClass: "control" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.since,
                expression: "since",
              },
            ],
            staticClass: "input",
            attrs: { type: "date" },
            domProps: { value: _vm.since },
            on: {
              change: function ($event) {
                return _vm.load_issues()
              },
              input: function ($event) {
                if ($event.target.composing) return
                _vm.since = $event.target.value
              },
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _vm.issues
        ? _c("table", { staticClass: "table is-fullwidth" }, [
            _c("thead", [
              _c("tr", [
                _c("th", [_vm._v("Revisions (diffs)")]),
                _vm._v(" "),
                _c("th", [_vm._v("Path")]),
                _vm._v(" "),
                _c("th", [_vm._v("Line")]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("Choice", {
                      attrs: {
                        choices: _vm.choices.publishable,
                        name: "publishable",
                      },
                      on: {
                        "new-choice": function ($event) {
                          return _vm.load_issues("publishable", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("th", [_vm._v("Message")]),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.issues, function (issue) {
                return _c("tr", [
                  _c(
                    "td",
                    [_c("RevisionDiffs", { attrs: { diffs: issue.diffs } })],
                    1
                  ),
                  _vm._v(" "),
                  _c("td", { staticClass: "mono" }, [
                    _vm._v(_vm._s(issue.path)),
                  ]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(issue.line))]),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c("p", [
                        issue.level == "error"
                          ? _c("span", { staticClass: "tag is-danger" }, [
                              _vm._v("Error"),
                            ])
                          : issue.level == "warning"
                          ? _c("span", { staticClass: "tag is-warning" }, [
                              _vm._v("Warning"),
                            ])
                          : _c("span", { staticClass: "tag is-dark" }, [
                              _vm._v(_vm._s(issue.level)),
                            ]),
                      ]),
                      _vm._v(" "),
                      _c("Bool", {
                        attrs: {
                          value: issue.publishable,
                          name: "Publishable",
                        },
                      }),
                      _vm._v(" "),
                      _c("Bool", {
                        attrs: { value: issue.in_patch, name: "In Patch" },
                      }),
                      _vm._v(" "),
                      _c("Bool", {
                        attrs: {
                          value: issue.new_for_revision,
                          name: "New for revision",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("td", [_c("pre", [_vm._v(_vm._s(issue.message))])]),
                ])
              }),
              0
            ),
          ])
        : _c("div", { staticClass: "notification is-info" }, [
            _vm._v("Loading check issues..."),
          ]),
      _vm._v(" "),
      _vm.issues && _vm.issues.length == 0
        ? _c("div", { staticClass: "notification is-info" }, [
            _vm._v("\n    No issues found with these filters\n  "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }