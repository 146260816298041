var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dropdown is-hoverable" }, [
    _c("div", { staticClass: "dropdown-trigger" }, [
      _c(
        "button",
        {
          staticClass: "button",
          attrs: { "aria-haspopup": "true", "aria-controls": "dropdown-menu" },
        },
        [
          _vm.current === null
            ? _c("span", [_vm._v(_vm._s(_vm.default_choice_name))])
            : _c("span", [_vm._v(_vm._s(_vm._f("name")(_vm.current)))]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "dropdown-menu",
        attrs: { id: "dropdown-menu", role: "menu" },
      },
      [
        _c(
          "div",
          { staticClass: "dropdown-content" },
          [
            _c(
              "a",
              {
                staticClass: "dropdown-item",
                class: { "is-active": _vm.current === null },
                on: {
                  click: function ($event) {
                    return _vm.select(null, $event)
                  },
                },
              },
              [_vm._v("\n        No filter\n      ")]
            ),
            _vm._v(" "),
            _c("hr", { staticClass: "dropdown-divider" }),
            _vm._v(" "),
            _vm._l(_vm.choices, function (choice) {
              return _c(
                "a",
                {
                  staticClass: "dropdown-item",
                  class: {
                    "is-active":
                      _vm.current === choice || _vm.current === choice.value,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.select(choice, $event)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm._f("name")(choice)) + "\n      "
                  ),
                ]
              )
            }),
          ],
          2
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }