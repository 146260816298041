var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", [
    _vm.value
      ? _c("span", { staticClass: "has-text-success" }, [
          _vm._v("✔ " + _vm._s(_vm.name)),
        ])
      : _c("span", { staticClass: "has-text-danger" }, [
          _vm._v("✕ " + _vm._s(_vm.name)),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }