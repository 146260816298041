var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c("Pagination", {
        attrs: {
          api_data: _vm.$store.state.diffs,
          name: "diffs",
          store_method: "load_diffs",
        },
      }),
      _vm._v(" "),
      _c("table", { staticClass: "table is-fullwidth" }, [
        _c("thead", [
          _c("tr", [
            _c("td", [_vm._v("#")]),
            _vm._v(" "),
            _c("td", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.query.search,
                    expression: "query.search",
                  },
                ],
                staticClass: "input",
                attrs: {
                  type: "text",
                  placeholder:
                    "Filter using phabricator, bugzilla Id or word, ...",
                },
                domProps: { value: _vm.query.search },
                on: {
                  keyup: function ($event) {
                    return _vm.use_search($event)
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.query, "search", $event.target.value)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("Choice", {
                  attrs: { choices: _vm.repositories, name: "repository" },
                  on: {
                    "new-choice": function ($event) {
                      return _vm.use_filter("repository", $event)
                    },
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("Choice", {
                  attrs: { choices: _vm.choices.issues, name: "issues" },
                  on: {
                    "new-choice": function ($event) {
                      return _vm.use_filter("issues", $event.value)
                    },
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("td", [_vm._v("Created")]),
            _vm._v(" "),
            _c("td", [_vm._v("Actions")]),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "tbody",
          _vm._l(_vm.diffs, function (diff) {
            return _c("tr", [
              _c("td", [
                _vm._v("\n          Diff " + _vm._s(diff.id) + "\n          "),
                _c("br"),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "mono",
                    attrs: {
                      href:
                        "https://firefox-ci-tc.services.mozilla.com/tasks/" +
                        diff.review_task_id,
                      target: "_blank",
                    },
                  },
                  [_vm._v(_vm._s(diff.review_task_id))]
                ),
              ]),
              _vm._v(" "),
              _c("td", [
                diff.revision.title
                  ? _c("p", [_vm._v(_vm._s(diff.revision.title))])
                  : _c("p", { staticClass: "has-text-danger" }, [
                      _vm._v("No title"),
                    ]),
                _vm._v(" "),
                _c(
                  "p",
                  [
                    _vm._v("\n            Revision\n            "),
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "revision",
                            params: { revisionId: diff.revision.id },
                          },
                        },
                      },
                      [_vm._v("D" + _vm._s(diff.revision.phabricator_id))]
                    ),
                    _vm._v(
                      "\n            @ base: " +
                        _vm._s(
                          _vm._f("short_repo")(diff.revision.base_repository)
                        ) +
                        " - head:\n            " +
                        _vm._s(
                          _vm._f("short_repo")(diff.revision.head_repository)
                        ) +
                        "\n          "
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("td", [
                _c(
                  "a",
                  {
                    attrs: {
                      href: diff.repository.url + "/rev/" + diff.mercurial_hash,
                      target: "_blank",
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(diff.mercurial_hash.substring(0, 8)) +
                        " @\n            " +
                        _vm._s(diff.repository.slug)
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "td",
                {
                  class: { "has-text-success": diff.nb_issues_publishable > 0 },
                },
                [
                  _c("p", [
                    diff.nb_issues_publishable > 0
                      ? _c("span", { staticClass: "tag is-success is-light" }, [
                          _vm._v(
                            _vm._s(diff.nb_issues_publishable) + " publishable"
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    diff.nb_warnings > 0
                      ? _c("span", { staticClass: "tag is-warning is-light" }, [
                          _vm._v(_vm._s(diff.nb_warnings) + " warnings"),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    diff.nb_errors > 0
                      ? _c("span", { staticClass: "tag is-danger is-light" }, [
                          _vm._v(_vm._s(diff.nb_errors) + " errors"),
                        ])
                      : _vm._e(),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("td", [
                _c("span", { attrs: { title: diff.created } }, [
                  _vm._v(_vm._s(_vm._f("since")(diff.created)) + " ago"),
                ]),
              ]),
              _vm._v(" "),
              _c("td", [
                _c(
                  "div",
                  { staticClass: "buttons" },
                  [
                    diff.nb_issues > 0
                      ? _c(
                          "router-link",
                          {
                            staticClass: "button is-primary is-small",
                            attrs: {
                              to: { name: "diff", params: { diffId: diff.id } },
                            },
                          },
                          [_vm._v("➕ Issues")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "dropdown is-hoverable" }, [
                      _vm._m(0, true),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "dropdown-menu",
                          attrs: { id: "dropdown-menu4", role: "menu" },
                        },
                        [
                          _c("div", { staticClass: "dropdown-content" }, [
                            _c("hr", { staticClass: "dropdown-divider" }),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                attrs: {
                                  href: diff.revision.phabricator_url,
                                  target: "_blank",
                                },
                              },
                              [
                                _vm._v(
                                  "Phabricator D" +
                                    _vm._s(diff.revision.phabricator_id)
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            diff.revision.bugzilla_id
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "dropdown-item",
                                    attrs: {
                                      href:
                                        "https://bugzil.la/" +
                                        diff.revision.bugzilla_id,
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "Bug " + _vm._s(diff.revision.bugzilla_id)
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                attrs: {
                                  href: _vm._f("treeherder_url")(diff),
                                  target: "_blank",
                                },
                              },
                              [_vm._v("Treeherder tasks")]
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
            ])
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _vm.diffs && _vm.diffs.length == 0 && _vm.query
        ? _c("div", { staticClass: "notification is-light" }, [
            _c("p", { staticClass: "is-inline-block has-text-weight-medium" }, [
              _vm._v(
                "\n      Sorry, no diffs are available for your search terms !\n    "
              ),
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "is-inline-block button is-success",
                on: {
                  click: function ($event) {
                    return _vm.reset_query()
                  },
                },
              },
              [_vm._v("\n      Reset search\n    ")]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "dropdown-trigger" }, [
      _c(
        "button",
        {
          staticClass: "button is-dark is-small",
          attrs: { "aria-haspopup": "true", "aria-controls": "dropdown-menu4" },
        },
        [_c("span", [_vm._v("🔩 Details")])]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }