var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _c("div", { staticClass: "buttons has-addons is-pulled-right" }, [
      _c(
        "button",
        {
          staticClass: "button is-small",
          class: { "is-primary": _vm.channel == "production" },
          on: {
            click: function ($event) {
              return _vm.load_tasks("production")
            },
          },
        },
        [_vm._v("\n      Production tasks\n    ")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "button is-small",
          class: { "is-primary": _vm.channel == "testing" },
          on: {
            click: function ($event) {
              return _vm.load_tasks("testing")
            },
          },
        },
        [_vm._v("\n      Testing tasks\n    ")]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "states" },
      _vm._l(_vm.states, function (state) {
        return _c("div", { staticClass: "state columns" }, [
          _c("div", { staticClass: "column is-one-third" }, [
            _c(
              "progress",
              {
                staticClass: "progress",
                class: {
                  "is-danger":
                    state.key.startsWith("error") || state.key === "killed",
                  "is-success": state.key == "done",
                  "is-info":
                    state.key != "done" && !state.key.startsWith("error"),
                },
                attrs: { max: "100" },
                domProps: { value: state.percent },
              },
              [_vm._v("\n          " + _vm._s(state.percent) + "%\n        ")]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "column is-one-third" }, [
            _c("strong", [_vm._v(_vm._s(state.name))]),
            _vm._v(" -\n        "),
            _c("span", { staticClass: "has-text-grey-light" }, [
              _vm._v(
                _vm._s(state.nb) +
                  "/" +
                  _vm._s(_vm.tasks_total) +
                  " tasks or\n          " +
                  _vm._s(state.percent) +
                  "%"
              ),
            ]),
          ]),
        ])
      }),
      0
    ),
    _vm._v(" "),
    _c("table", { staticClass: "table is-fullwidth" }, [
      _c("thead", [
        _c("tr", [
          _c("td", [_vm._v("#")]),
          _vm._v(" "),
          _c("td", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.filters.revision,
                  expression: "filters.revision",
                },
              ],
              staticClass: "input",
              attrs: {
                type: "text",
                placeholder:
                  "Filter using phabricator, bugzilla Id or word, ...",
              },
              domProps: { value: _vm.filters.revision },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.filters, "revision", $event.target.value)
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "td",
            [
              _c("Choice", {
                attrs: { choices: _vm.repositories, name: "repo" },
                on: {
                  "new-choice": function ($event) {
                    _vm.filters.repository = $event
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "td",
            [
              _c("Choice", {
                attrs: { choices: _vm.states, name: "state" },
                on: {
                  "new-choice": function ($event) {
                    _vm.filters.state = $event
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "td",
            [
              _c("Choice", {
                attrs: { choices: _vm.choices.issues, name: "issue" },
                on: {
                  "new-choice": function ($event) {
                    _vm.filters.issues = $event
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("td", [_vm._v("Indexed")]),
          _vm._v(" "),
          _c("td", [_vm._v("Actions")]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "tbody",
        _vm._l(_vm.tasks, function (task) {
          return _c("tr", [
            _c("td", [
              _c(
                "a",
                {
                  staticClass: "mono",
                  attrs: {
                    href:
                      "https://firefox-ci-tc.services.mozilla.com/tasks/" +
                      task.taskId,
                    target: "_blank",
                  },
                },
                [_vm._v(_vm._s(task.taskId))]
              ),
            ]),
            _vm._v(" "),
            _c("td", [
              task.data.title
                ? _c("p", [_vm._v(_vm._s(task.data.title))])
                : _c("p", { staticClass: "has-text-danger" }, [
                    _vm._v("No title"),
                  ]),
              _vm._v(" "),
              _c("p", [
                _c("small", { staticClass: "mono has-text-grey-light" }, [
                  _vm._v(_vm._s(task.data.diff_phid)),
                ]),
                _vm._v(
                  "\n            - diff " +
                    _vm._s(task.data.diff_id || "unknown") +
                    "\n          "
                ),
              ]),
              _vm._v(" "),
              _c(
                "p",
                [
                  _c("small", { staticClass: "mono has-text-grey-light" }, [
                    _vm._v(_vm._s(task.data.phid)),
                  ]),
                  _vm._v("\n            -\n            "),
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "revision",
                          params: { revisionId: task.data.id },
                        },
                      },
                    },
                    [_vm._v("rev " + _vm._s(task.data.id))]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("td", [
              task.data.repository == "https://hg.mozilla.org/mozilla-central"
                ? _c("span", { staticClass: "tag is-primary" }, [
                    _vm._v("Mozilla Central"),
                  ])
                : task.data.repository == "https://hg.mozilla.org/projects/nss"
                ? _c("span", { staticClass: "tag is-info" }, [_vm._v("NSS")])
                : _c("span", { staticClass: "tag is-dark" }, [
                    _vm._v(_vm._s(task.data.repository || "Unknown")),
                  ]),
            ]),
            _vm._v(" "),
            _c("td", [
              task.data.state == "started"
                ? _c("span", { staticClass: "tag is-light" }, [
                    _vm._v("Started"),
                  ])
                : task.data.state == "cloned"
                ? _c("span", { staticClass: "tag is-info" }, [_vm._v("Cloned")])
                : task.data.state == "analyzing"
                ? _c("span", { staticClass: "tag is-info" }, [
                    _vm._v("Analyzing"),
                  ])
                : task.data.state == "analyzed"
                ? _c("span", { staticClass: "tag is-primary" }, [
                    _vm._v("Analyzed"),
                  ])
                : task.data.state == "killed"
                ? _c("span", { staticClass: "tag is-danger" }, [
                    _vm._v("\n            Killed for timeout\n          "),
                  ])
                : task.data.state == "error"
                ? _c(
                    "span",
                    {
                      staticClass: "tag is-danger",
                      attrs: { title: task.data.error_message },
                    },
                    [
                      _vm._v(
                        "\n            Error: " +
                          _vm._s(task.data.error_code || "unknown") +
                          "\n          "
                      ),
                    ]
                  )
                : task.data.state == "done"
                ? _c("span", { staticClass: "tag is-success" }, [
                    _vm._v("Done"),
                  ])
                : _c("span", { staticClass: "tag is-black" }, [
                    _vm._v("Unknown"),
                  ]),
            ]),
            _vm._v(" "),
            _c(
              "td",
              {
                class: { "has-text-success": task.data.issues_publishable > 0 },
              },
              [
                task.data.issues_publishable > 0
                  ? _c("span", [_vm._v(_vm._s(task.data.issues_publishable))])
                  : task.data.issues_publishable == 0
                  ? _c("span", [_vm._v(_vm._s(task.data.issues_publishable))])
                  : _c("span", [_vm._v("-")]),
                _vm._v(
                  "\n          / " + _vm._s(task.data.issues) + "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c("td", [
              _c("span", { attrs: { title: task.data.indexed } }, [
                _vm._v(_vm._s(_vm._f("since")(task.data.indexed)) + " ago"),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "td",
              [
                _c(
                  "a",
                  {
                    staticClass: "button is-link",
                    attrs: { href: task.data.url, target: "_blank" },
                  },
                  [_vm._v("Phabricator")]
                ),
                _vm._v(" "),
                task.data.bugzilla_id
                  ? _c(
                      "a",
                      {
                        staticClass: "button is-dark",
                        attrs: {
                          href: "https://bugzil.la/" + task.data.bugzilla_id,
                          target: "_blank",
                        },
                      },
                      [_vm._v("Bugzilla")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "button is-primary",
                    attrs: {
                      href:
                        "https://firefox-ci-tc.services.mozilla.com/tasks/" +
                        task.data.try_group_id,
                      target: "_blank",
                    },
                  },
                  [_vm._v("Try Tasks")]
                ),
                _vm._v(" "),
                task.data.issues > 0
                  ? _c(
                      "router-link",
                      {
                        staticClass: "button is-primary",
                        attrs: {
                          to: {
                            name: "diff",
                            params: { diffId: task.data.diff_id },
                          },
                        },
                      },
                      [_vm._v("Issues")]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }