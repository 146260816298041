var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.total > 0
    ? _c("nav", { staticClass: "columns" }, [
        _c("div", { staticClass: "column" }, [
          _c(
            "button",
            {
              staticClass: "button is-pulled-right is-dark",
              attrs: { disabled: !_vm.has_next },
              on: { click: _vm.load_next_page },
            },
            [_vm._v("\n      Older " + _vm._s(_vm.name) + "↣\n    ")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "button is-pulled-right is-dark",
              attrs: { disabled: !_vm.has_previous },
              on: { click: _vm.load_previous_page },
            },
            [_vm._v("\n      ↞ Newer " + _vm._s(_vm.name) + "\n    ")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "is-text-dark is-pulled-right" }, [
            _vm._v(
              "\n      Showing " +
                _vm._s(_vm.page_nb) +
                "/" +
                _vm._s(_vm.total) +
                " " +
                _vm._s(_vm.name) +
                "\n    "
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }