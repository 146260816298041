var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _vm.state == "loading"
      ? _c("div", { staticClass: "notification is-info" }, [
          _vm._v("Loading..."),
        ])
      : _vm.state == "loaded"
      ? _c(
          "div",
          [
            _c("h1", { staticClass: "title" }, [
              _vm._v("Revision D" + _vm._s(_vm.revision.phabricator_id)),
            ]),
            _vm._v(" "),
            _c("h2", { staticClass: "subtitle" }, [
              _vm._v(_vm._s(_vm.revision.title)),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v("\n      On "),
              _c("strong", [_vm._v(_vm._s(_vm.revision.head_repository))]),
              _vm._v(" -\n      "),
              _c(
                "a",
                {
                  attrs: {
                    href: _vm.revision.phabricator_url,
                    target: "_blank",
                  },
                },
                [_vm._v("View on Phabricator")]
              ),
              _vm._v(" "),
              _vm.revision.bugzilla_id
                ? _c("span", [
                    _vm._v("\n        -\n        "),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "https://bugzil.la/" + _vm.revision.bugzilla_id,
                          target: "_blank",
                        },
                      },
                      [_vm._v("View Bug " + _vm._s(_vm.revision.bugzilla_id))]
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _vm._l(_vm.paths, function (path) {
              return _c("nav", { staticClass: "panel" }, [
                _c("p", { staticClass: "panel-heading" }, [
                  _vm._v("\n        " + _vm._s(path) + "\n      "),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "panel-block" }, [
                  _c(
                    "div",
                    { staticClass: "columns" },
                    _vm._l(_vm.revision.diffs, function (diff) {
                      return _c("div", { staticClass: "column" }, [
                        _c(
                          "p",
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "diff",
                                    params: { diffId: diff.id },
                                  },
                                },
                              },
                              [_vm._v("Diff " + _vm._s(diff.id))]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "table",
                          { staticClass: "table" },
                          [
                            _vm._m(0, true),
                            _vm._v(" "),
                            _vm._l(
                              _vm.path_issues(diff.id, path),
                              function (issue) {
                                return _c(
                                  "tr",
                                  {
                                    class: {
                                      new_for_revision: issue.new_for_revision,
                                    },
                                  },
                                  [
                                    _c("td", [
                                      issue.new_for_revision
                                        ? _c("span", [_vm._v("✔")])
                                        : _c("span", [_vm._v("❌")]),
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [_vm._v(_vm._s(issue.line))]),
                                    _vm._v(" "),
                                    _c("td", [_vm._v(_vm._s(issue.char))]),
                                    _vm._v(" "),
                                    _c("td", [_vm._v(_vm._s(issue.analyzer))]),
                                    _vm._v(" "),
                                    _c("td", [_vm._v(_vm._s(issue.check))]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _c("samp", [
                                        _vm._v(
                                          _vm._s(issue.hash.substring(0, 8))
                                        ),
                                      ]),
                                    ]),
                                  ]
                                )
                              }
                            ),
                          ],
                          2
                        ),
                      ])
                    }),
                    0
                  ),
                ]),
              ])
            }),
          ],
          2
        )
      : _c("div", { staticClass: "notification is-danger" }, [
          _c("h4", { staticClass: "title" }, [_vm._v("Error")]),
          _vm._v("\n    " + _vm._s(_vm.state) + "\n  "),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", [_vm._v("New")]),
      _vm._v(" "),
      _c("th", [_vm._v("Line")]),
      _vm._v(" "),
      _c("th", [_vm._v("Char")]),
      _vm._v(" "),
      _c("th", [_vm._v("Analyzer")]),
      _vm._v(" "),
      _c("th", [_vm._v("Check")]),
      _vm._v(" "),
      _c("th", [_vm._v("Hash")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }