var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h1", { staticClass: "title" }, [_vm._v(_vm._s(_vm.name))]),
    _vm._v(" "),
    _vm.stats && _vm.stats.ids
      ? _c("h2", { staticClass: "subtitle" }, [
          _c("span", [
            _vm._v("\n      Loaded " + _vm._s(_vm.stats.loaded) + "\n      "),
            _vm.stats.errors > 0
              ? _c(
                  "span",
                  {
                    staticClass: "has-text-danger",
                    attrs: {
                      title: _vm.stats.errors + " errors while loading reports",
                    },
                  },
                  [_vm._v("\n        + " + _vm._s(_vm.stats.errors))]
                )
              : _vm._e(),
            _vm._v(
              "\n      / " +
                _vm._s(_vm.stats.ids.length) +
                " tasks with issues\n    "
            ),
          ]),
          _vm._v(" "),
          _vm.stats && _vm.stats.start_date
            ? _c("span", { attrs: { title: _vm.stats.start_date } }, [
                _vm._v(
                  ", since " +
                    _vm._s(_vm._f("since")(_vm.stats.start_date)) +
                    " ago"
                ),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.stats
      ? _c("div", [
          _c(
            "progress",
            {
              staticClass: "progress is-info",
              class: {
                "is-info": _vm.progress < 100,
                "is-success": _vm.progress >= 100,
              },
              attrs: { max: "100" },
              domProps: { value: _vm.progress },
            },
            [_vm._v("\n      " + _vm._s(_vm.progress) + "%\n    ")]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }