var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "app" } }, [
    _c("main", [
      _c(
        "nav",
        {
          staticClass: "navbar is-dark",
          attrs: { role: "navigation", "aria-label": "main navigation" },
        },
        [
          _c("div", { staticClass: "container is-fluid" }, [
            _c(
              "div",
              { staticClass: "navbar-brand" },
              [
                _c("router-link", {
                  attrs: { to: "/" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({
                        href,
                        route,
                        navigate,
                        isActive,
                        isExactActive,
                      }) {
                        return [
                          _c(
                            "a",
                            {
                              staticClass: "navbar-item",
                              attrs: { href: href },
                              on: { click: navigate },
                            },
                            [_vm._v("🤖 Code Review Bot")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "navbar-menu" }, [
              _c("div", { staticClass: "navbar-start" }, [
                _c("div", { staticClass: "navbar-item" }, [
                  _c("span", { staticClass: "navbar-item" }, [
                    _vm._v("Connected to " + _vm._s(_vm.backend_url)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "navbar-end" },
                [
                  _c("router-link", {
                    attrs: { to: "/stats" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({
                          href,
                          route,
                          navigate,
                          isActive,
                          isExactActive,
                        }) {
                          return [
                            _c(
                              "a",
                              {
                                staticClass: "navbar-item",
                                class: { "is-active": isExactActive },
                                attrs: { href: href },
                                on: { click: navigate },
                              },
                              [_vm._v("💥 All checks")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("router-link", {
                    attrs: { to: "/" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({
                          href,
                          route,
                          navigate,
                          isActive,
                          isExactActive,
                        }) {
                          return [
                            _c(
                              "a",
                              {
                                staticClass: "navbar-item",
                                class: { "is-active": isExactActive },
                                attrs: { href: href },
                                on: { click: navigate },
                              },
                              [_vm._v("🔍 Browse diffs")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("router-link", {
                    attrs: { to: "/tasks" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({
                          href,
                          route,
                          navigate,
                          isActive,
                          isExactActive,
                        }) {
                          return [
                            _c(
                              "a",
                              {
                                staticClass: "navbar-item",
                                class: { "is-active": isExactActive },
                                attrs: { href: href },
                                on: { click: navigate },
                              },
                              [_vm._v("📜 All tasks")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "container is-fluid" }, [_c("router-view")], 1),
    ]),
    _vm._v(" "),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("footer", [
      _vm._v("\n    Built by\n    "),
      _c(
        "a",
        {
          attrs: {
            href: "https://wiki.mozilla.org/Release_Management",
            target: "_blank",
          },
        },
        [_vm._v("Release Management team")]
      ),
      _vm._v(" "),
      _c("span", [_vm._v("•")]),
      _vm._v(" "),
      _c(
        "a",
        {
          attrs: {
            href: "https://github.com/mozilla/code-review/tree/master/frontend",
            target: "_blank",
          },
        },
        [_vm._v("Source Code")]
      ),
      _vm._v(" "),
      _c("span", [_vm._v("•")]),
      _vm._v(" "),
      _c(
        "a",
        {
          attrs: {
            href: "https://github.com/mozilla/code-review/issues",
            target: "_blank",
          },
        },
        [_vm._v("Report an issue")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }