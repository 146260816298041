var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.groupedDiffs, function (group) {
      return _c(
        "p",
        { staticClass: "is-nowrap" },
        [
          _c(
            "a",
            {
              attrs: { href: group.revision.phabricator_url, target: "_blank" },
            },
            [_vm._v("D" + _vm._s(group.revision.phabricator_id))]
          ),
          _vm._v("\n    (" + _vm._s(group.msg)),
          group.remaining >= 1
            ? [_vm._v(" +" + _vm._s(group.remaining))]
            : _vm._e(),
          _vm._v(")\n  "),
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }