var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h1", { staticClass: "title" }, [
      _vm._v("Diff " + _vm._s(_vm.$route.params.diffId)),
    ]),
    _vm._v(" "),
    _vm.state == "loading"
      ? _c("div", { staticClass: "notification is-info" }, [
          _vm._v("\n    Loading diff...\n  "),
        ])
      : _vm.state == "missing"
      ? _c("div", { staticClass: "notification is-warning" }, [
          _vm._v("\n    No diff, so no issues !\n  "),
        ])
      : _vm.state == "error"
      ? _c("div", { staticClass: "notification is-danger" }, [
          _vm._v("\n    Failure\n  "),
        ])
      : _vm.state == "loaded"
      ? _c("div", [
          _vm.diff && _vm.diff.id
            ? _c("nav", { staticClass: "level" }, [
                _c("div", { staticClass: "level-item has-text-centered" }, [
                  _c("div", [
                    _c("p", { staticClass: "heading" }, [
                      _vm._v("Publishable"),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "title" }, [
                      _vm._v(_vm._s(_vm.nb_publishable)),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "level-item has-text-centered" }, [
                  _c("div", [
                    _c("p", { staticClass: "heading" }, [_vm._v("Issues")]),
                    _vm._v(" "),
                    _c("p", { staticClass: "title" }, [
                      _vm._v(_vm._s(_vm.all_issues.length)),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "level-item has-text-centered" }, [
                  _c("div", [
                    _c("p", { staticClass: "heading" }, [_vm._v("Source")]),
                    _vm._v(" "),
                    _c("p", { staticClass: "title" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: _vm.diff.revision.url,
                            target: "_blank",
                          },
                        },
                        [_vm._v(_vm._s(_vm.diff.revision.title))]
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "level-item has-text-centered" }, [
                  _c("div", [
                    _c("p", { staticClass: "heading" }, [_vm._v("Diffed")]),
                    _vm._v(" "),
                    _c("p", { staticClass: "title" }, [
                      _vm._v(_vm._s(_vm._f("from_timestamp")(_vm.diff.time))),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.issues
            ? _c("table", { staticClass: "table is-fullwidth" }, [
                _c("thead", [
                  _c("tr", [
                    _c("td", [_vm._v("Hash")]),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        _c("Choice", {
                          attrs: { choices: _vm.analyzers, name: "analyzer" },
                          on: {
                            "new-choice": function ($event) {
                              _vm.filters.analyzer = $event
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        _c("Choice", {
                          attrs: { choices: _vm.paths, name: "path" },
                          on: {
                            "new-choice": function ($event) {
                              _vm.filters.path = $event
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("td", [_vm._v("Lines")]),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        _c("Choice", {
                          attrs: {
                            choices: _vm.choices.publishable,
                            name: "publishable",
                          },
                          on: {
                            "new-choice": function ($event) {
                              _vm.filters.publishable = $event
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("td", [_vm._v("Check")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("Level")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("Message")]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.issues, function (issue) {
                    return _c(
                      "tr",
                      { class: { publishable: issue.publishable } },
                      [
                        _c("td", [
                          _c("samp", [
                            _vm._v(_vm._s(issue.hash.substring(0, 12))),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c("span", [_vm._v(_vm._s(issue.analyzer))]),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "path" }, [
                          _vm._v(_vm._s(issue.path)),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n            " +
                              _vm._s(issue.line) +
                              "\n            "
                          ),
                          issue.nb_lines > 1
                            ? _c("span", [
                                _vm._v(
                                  "→ " + _vm._s(issue.line - 1 + issue.nb_lines)
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "p",
                            [
                              _c("Bool", {
                                attrs: {
                                  value: issue.publishable,
                                  name: "Publishable",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            [
                              _c("Bool", {
                                attrs: {
                                  value: issue.new_for_revision,
                                  name: "New for revision",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            [
                              _c("Bool", {
                                attrs: {
                                  value: issue.in_patch,
                                  name: "In Patch",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", [_c("span", [_vm._v(_vm._s(issue.check))])]),
                        _vm._v(" "),
                        _c("td", [
                          issue.level == "error"
                            ? _c("span", { staticClass: "tag is-danger" }, [
                                _vm._v("Error"),
                              ])
                            : issue.level == "warning"
                            ? _c("span", { staticClass: "tag is-warning" }, [
                                _vm._v("Warning"),
                              ])
                            : _c("span", { staticClass: "tag is-dark" }, [
                                _vm._v(_vm._s(issue.level)),
                              ]),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c("pre", [_vm._v(_vm._s(issue.message))]),
                          _vm._v(" "),
                          issue.body
                            ? _c("pre", [
                                _vm._v(
                                  "              " +
                                    _vm._s(issue.body) +
                                    "\n            "
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ])
            : _vm._e(),
        ])
      : _c("div", { staticClass: "notification is-info" }, [
          _vm._v("No issues !"),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }